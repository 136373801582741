import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FlexibleContentWrapper from "../components/flexible-content-wrapper"

import { Fade } from "react-awesome-reveal";

const IndexPage = ({ data }) => {
  const flexibleContent = data.craftHomeHomeEntry.flexibleContent

  return (
    <Layout>
      <Seo title="Mikään ei ole ikuista" />
      <FlexibleContentWrapper flexibleContent={flexibleContent} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    craftHomeHomeEntry {
      title
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
